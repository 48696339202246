

































import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'info',
})
export default class InfoItems extends Vue { }
