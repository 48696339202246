import { render, staticRenderFns } from "./TheAbout.vue?vue&type=template&id=0aa60c81&scoped=true&"
import script from "./TheAbout.vue?vue&type=script&lang=ts&"
export * from "./TheAbout.vue?vue&type=script&lang=ts&"
import style0 from "./TheAbout.vue?vue&type=style&index=0&id=0aa60c81&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aa60c81",
  null
  
)

export default component.exports