




















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'aboutMe',
})
export default class TheAbout extends Vue { }