









import { Component, Vue } from 'vue-property-decorator';
import IconItem from '@/components/IconItem.vue';

@Component({
  name: 'icon',
  components: { IconItem },
})
export default class IconInfo extends Vue { }
