









import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'iconInfo',
})
export default class IconItem extends Vue {
  private dataitems: Array<{ id: string, text: string }> = [
    {
      id: '1',
      text: 'Mauris a laoreet sem. Nam turpis purus, ',
    },
    {
      id: '2',
      text: 'Mauris a laoreet sem. Nam turpis purus, ',
    },
    {
      id: '3',
      text: 'Mauris a laoreet sem. Nam turpis purus, ',
    },
    {
      id: '4',
      text: 'Mauris a laoreet sem. Nam turpis purus, ',
    },
  ]
}
