



























import { Component, Vue } from 'vue-property-decorator';

import navbar from '@/components/TheNavbar.vue';
import hero from '@/components/TheHero.vue';
import info from '@/components/InfoItems.vue';
import textInfo from '@/components/InfoText.vue';
import icons from '@/components/IconInfo.vue';
import aboutMe from '@/components/TheAbout.vue';
import theFooter from '@/components/TheFooter.vue';

@Component({
  name: 'home',
  components: {
    navbar,
    hero,
    info,
    textInfo,
    icons,
    aboutMe,
    theFooter,
  },
})
export default class Home extends Vue { }
