












import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'textInfo',
})
export default class InfoText extends Vue { }
